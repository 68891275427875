
.project-classify {
    & * {
      box-sizing: border-box;
    }
    display: flex;
    height: fit-content;
    background-color: #fff;
    .classify-content {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        //justify-content: space-between;
        height: fit-content;
    }
    .project-classify-list {
        display: flex;
        flex: 1;
        flex-direction: column;
        //width: 1%;
        min-width: 1136px;
        height: 100%;
        .header-select {
            width: 100%;
            height: auto;
            background-color: #fff;
            .item {
                display: flex;
                flex-wrap: wrap;
                color: #666666;
                font-size: 16px;
                padding: 20px 70px;
                .sort-item {
                    min-width: 70px;
                    height: 32px;
                    line-height: 32px;
                    text-align: center;
                    margin: 0 10px 5px 10px;
                    padding: 0 10px;
                    &:hover {
                        cursor: pointer;
                        color: #3889FF;
                    }
                }
                .current_first {
                    background: linear-gradient(0deg, #0097EB 0%, #75D7F4 100%);
                    border-radius: 16px;
                    color: #fff;
                }
            }
            .second-item {
                font-size: 14px;
                .current_second {
                    background: linear-gradient(0deg, #0097EB 0%, #75D7F4 100%);
                    border-radius: 16px;
                    color: #fff;
                    font-size: 14px;
                }
            }
        }
        .third-class-list {
            .category-list {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: flex-start;
            }
            .page-content {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            display: flex;
            flex: 1;
            width: 100%;
            //height: calc(100vh - 298px);
            /*min-width: 1020px;*/
            padding: 10px 20px 10px 70px;
            box-sizing: border-box;
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
                .el-scrollbar__view {
                    display: flex;
                    flex-wrap: wrap;
                }
            }
            .third-class-item {
                display: flex;
                width: calc((100% - 32px)/2);
                min-width: 500px;
                height: 190px;
                margin: 8px 8px 16px 8px;
                &:hover {
                    cursor: pointer;
                    box-shadow: 0 0 8px 0 rgba(16, 160, 236, 0.2);
                    border-radius: 0 4px 4px 0;
                }
                &:nth-child(2n+1) {
                    margin: 8px 8px 16px 8px;
                }
                .cover {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 258px;
                    height: 100%;
                    .no-image {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        &:deep(.el-image) {
                          i {
                            font-size: 100px;
                            color: #EEEEEE;
                          }
                        }

                    }
                    img {
                        /*max-width: 100%;*/
                        /*max-height: 100%;*/
                    }
                }
                .third-class-info {
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    justify-content: space-around;
                    width: 1%;
                    min-width: 290px;
                    padding: 27px;
                    .class-name {
                        font-size: 22px;
                        display: block;
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .class-time-difficulty {
                        display: flex;
                        color: #999999;
                        font-size: 14px;
                        .time {
                            margin-right: 20px;
                        }
                        .difficulty {
                            i {
                                color: #FFD83E;
                            }
                        }
                    }
                    .class-view-like {
                        display: flex;
                        color: #7D7D7D;
                        .view, .like {
                            display: flex;
                            align-items: center;
                            i {
                                margin-right: 5px;
                            }
                        }
                        .view {
                            margin-right: 30px;
                        }
                    }
                }
            }
        }
    }
    .project-guess-list {
        display: flex;
        flex-direction: column;
        width: 400px;
        padding: 18px 20px 0;
        box-sizing: border-box;
        .guess-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 30px;
            margin-bottom: 15px;
            .guess-text {
                display: flex;
                align-items: center;
                height: 100%;
                img.header-img {
                    width: 23px;
                    height: 19px;
                    margin-right: 16px;
                }
                span.header-text {
                    font-size: 18px;
                }
            }
            .switch {
                display: flex;
                align-items: center;
                color: #3889ff;
                cursor: pointer;
                i {
                    margin-right: 10px;
                }
            }
        }
        .guess-content {
            flex: 1;
            height: 1%;
            .guess-list {
                height: 100%;
                ::v-deep .el-scrollbar {
                    height: 100%;
                }
                ::v-deep .el-scrollbar__wrap {
                    overflow-x: hidden;
                }
                .guess-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 40px;
                    img {
                        width: 29px;
                        height: 23px;
                    }
                    .guess-title {
                        display: flex;
                        flex: 1;
                        width: 1%;
                        color: #666666;
                        font-size: 16px;
                        margin: 0 30px 0 10px;
                        overflow: hidden;
                        text-overflow:ellipsis;
                        white-space: nowrap;
                        cursor: pointer;
                    }
                    .num {
                        color: #666666;
                        font-size: 14px;
                    }
                    .rank {
                        display: block;
                        width: 29px;
                        font-size: 16px;
                        color: #999;
                        font-weight: bold;
                        text-align: center;
                    }
                }
            }
        }
    }
    .pages-left {
        text-align: center;
    }
}
.no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.nums {
    display: block;
    width: 23px;
    text-align: center;
}
